const formatDateToGMT2 = (date) => {
    const offset = 2 * 60;
    const newDate = new Date(date.getTime() + offset * 60000);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const slotToDate = (slot, selectedDate) => {
    const hours = Math.floor(slot / 2);
    const minutes = (slot % 2) * 30;
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  const isSameDay = (date1, date2) => {
	return date1.getFullYear() === date2.getFullYear() &&
	  date1.getMonth() === date2.getMonth() &&
	  date1.getDate() === date2.getDate();
  };

  export { formatDateToGMT2, slotToDate, isSameDay };
