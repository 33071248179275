import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Spinner, ProgressBar, Alert } from 'react-bootstrap';
import { format } from 'date-fns';
import { nl, fr } from 'date-fns/locale';
import './css/ReservationModal.css';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { slotToTime, slotToQuarterTime } from '../utils/converters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faUser, faEnvelope, faPhone, faArrowDown, faInfoCircle, faCalendarAlt, faClock, faUsers } from '@fortawesome/free-solid-svg-icons';

const ReservationModal = ({
  showModal,
  setShowModal,
  guests,
  selectedDate,
  selectedSlot,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  handleSubmit,
  handleGuestsClick,
  language,
  selectedMenu,
  setSelectedMenu,
  extraInfo,
  setExtraInfo,
  isQuarterSlot,
  agreedTerms,
  setAgreedTerms,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [typing, setTyping] = useState({});
  const [showAlert, setShowAlert] = useState(false); // State to show/hide alert
  const [showAlertVerified, setShowAlertVerified] = useState(false); // State to show/hide alert

  const [showAlertPrivacy, setShowAlertPrivacy] = useState(false);
  const emailDomains = ['@gmail.com', '@hotmail.com', '@telenet.be'];
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [emailVerificationAttempts, setEmailVerificationAttempts] = useState(0);
  const MAX_EMAIL_ATTEMPTS = 10;

const handleFocus = (field) => {
  setFocusedField(field);
};

  const fetchMenuItems = async () => {
    try {
      if (window.menu && window.menu.length > 0) {
        setMenuItems(window.menu.map((item, index) => ({
          id: index,
          display_description: item
        })));
      } else {
        const response = await fetch(window.baseDomain + `tables/menu-client/${window.restaurantNumber}/`);
        const data = await response.json();
        setMenuItems(data);
      }
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleKeyPress = (event, nextInputId, prevInputId) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault(); // Prevent default form behavior
  
      // If Shift key is held down, navigate to the previous field
      if (event.shiftKey) {
        const prevInput = document.getElementById(prevInputId);
        if (prevInput) {
          prevInput.focus();
        }
      } else {
        // Otherwise, navigate to the next field
        const nextInput = document.getElementById(nextInputId);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };
  


  const isAllDataFilled = () => {
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedEmail = localStorage.getItem('email');
    const storedPhone = localStorage.getItem('phone');

    return storedFirstName && storedFirstName.trim() !== '' &&
           storedLastName && storedLastName.trim() !== '' &&
           storedEmail && storedEmail.trim() !== '' &&
           storedPhone && storedPhone.trim() !== '';
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  useEffect(() => {
    if (isAllDataFilled()) {
      const storedFirstName = localStorage.getItem('firstName');
      const storedLastName = localStorage.getItem('lastName');
      const storedEmail = localStorage.getItem('email');
      const storedPhone = localStorage.getItem('phone');

      setFirstName(storedFirstName);
      setLastName(storedLastName);
      setEmail(storedEmail);
      setPhone(storedPhone);
    } else {
      setIsEditing(true);
    }
  }, [setFirstName, setLastName, setEmail, setPhone]);

  useEffect(() => {
    if (firstName.trim() !== '') localStorage.setItem('firstName', firstName);
  }, [firstName]);

  useEffect(() => {
    if (lastName.trim() !== '') localStorage.setItem('lastName', lastName);
  }, [lastName]);

  useEffect(() => {
    if (email.trim() !== '') localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    if (phone.trim() !== '') localStorage.setItem('phone', phone);
  }, [phone]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateField = (field, value) => {
    const newErrors = { ...errors };
    switch (field) {
      case 'firstName':
        if (!value.trim()) {
          newErrors.firstName = language === 'NL' ? 'Voornaam is verplicht' : language === 'FR' ? 'Le prénom est requis' : 'First name is required';
        } else {
          delete newErrors.firstName;
        }
        break;
      case 'lastName':
        if (!value.trim()) {
          newErrors.lastName = language === 'NL' ? 'Achternaam is verplicht' : language === 'FR' ? 'Le nom est requis' : 'Last name is required';
        } else {
          delete newErrors.lastName;
        }
        break;
      case 'email':
        if (!value.trim() || !validateEmail(value)) {
          newErrors.email = language === 'NL' ? 'Ongeldig emailadres' : language === 'FR' ? 'Adresse e-mail invalide' : 'Invalid email address';
        } else {
          delete newErrors.email;
        }
        break;
      case 'phone':
        if (!value.trim()) {
          newErrors.phone = language === 'NL' ? 'Telefoonnummer is verplicht' : language === 'FR' ? 'Le numéro de téléphone est requis' : 'Phone number is required';
        } else {
          delete newErrors.phone;
        }
        break;
      case 'agreedTerms':
        if (!value || !agreedTerms) {
          newErrors.agreedTerms = language === 'NL' ? 'U moet akkoord gaan met de voorwaarden' : language === 'FR' ? 'Vous devez accepter les termes' : 'You must agree to the terms';
        } else {
          delete newErrors.agreedTerms;
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };


  const verifyEmail = async () => {
    try {
      const response = await fetch('https://fast-api-validator-0c75d559a522.herokuapp.com/verify-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      return false;
    }
  };

  const handleFormSubmit = async () => {
    // Perform validation checks for all fields
    const isFirstNameValid = firstName.trim() !== '';
    const isLastNameValid = lastName.trim() !== '';
    const isEmailValid = validateEmail(email);
    const isPhoneValid = phone.trim() !== '';
  
    // Set validation state
    setFirstNameValid(isFirstNameValid);
    setLastNameValid(isLastNameValid);
    setEmailValid(isEmailValid);
    setPhoneValid(isPhoneValid);
  
    // Display errors if fields are invalid
    setErrors({
      firstName: !isFirstNameValid
        ? language === 'NL'
          ? 'Voornaam is verplicht'
          : language === 'FR'
          ? 'Le prénom est requis'
          : 'First name is required'
        : null,
      lastName: !isLastNameValid
        ? language === 'NL'
          ? 'Achternaam is verplicht'
          : language === 'FR'
          ? 'Le nom est requis'
          : 'Last name is required'
        : null,
      email: !isEmailValid
        ? language === 'NL'
          ? 'Ongeldig emailadres'
          : language === 'FR'
          ? 'Adresse e-mail invalide'
          : 'Invalid email address'
        : null,
      phone: !isPhoneValid
        ? language === 'NL'
          ? 'Telefoonnummer is verplicht'
          : language === 'FR'
          ? 'Le numéro de téléphone est requis'
          : 'Phone number is required'
        : null,
    });
  
    // Check if all fields are valid before proceeding with form submission
    if (!isFirstNameValid || !isLastNameValid || !isEmailValid || !isPhoneValid || !agreedTerms) {
      setShowAlert(true);
      return;
    }

  
    // Email verification flow
    if (emailVerificationAttempts < MAX_EMAIL_ATTEMPTS) {
      const isEmailVerified = await verifyEmail();
      if (!isEmailVerified) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email:
            language === 'NL'
              ? 'Controleer uw e-mail'
              : language === 'FR'
              ? 'Vérifiez votre email'
              : 'Please check your email',
        }));
        setShowAlertVerified(true);
        setEmailVerificationAttempts(emailVerificationAttempts + 1);
        return;
      }
    }
  
    setIsSubmitting(true);
    try {
      await handleSubmit();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
      setProgress(0);
    }
  };
  
  const handleTyping = (field, value) => {
    setTyping((prevTyping) => ({ ...prevTyping, [field]: true }));
    eval(`set${field.charAt(0).toUpperCase() + field.slice(1)}`)(value);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    handleTyping('email', newEmail);
    const atPosition = newEmail.indexOf('@');
    if (atPosition === -1) {
      setEmailSuggestions(emailDomains);
    } else {
      setEmailSuggestions([]);
    }
  };

  function isMonthValid(ruleset, date) {
    const monthRule = ruleset.match(/_m[1-9]|_m1[0-2]/);
    if (!monthRule) return true;
    const ruleMonth = parseInt(monthRule[0].slice(2), 10);
    const selectedMonth = date ? date.getMonth() + 1 : 'N/A';
    return ruleMonth === selectedMonth;
  }

  function isDayValid(ruleset, date) {
    const dayRules = ruleset.match(/d[1-7]/g);
    if (!dayRules) return true;
    const selectedDay = date ? date.getDay() : 'N/A';
    if (date) {
      return dayRules.some(d => {
        const ruleDay = parseInt(d.slice(1), 10);
        return ruleDay === selectedDay;
      });
    }
    return true;
  }

  const filteredMenuItems = menuItems.filter(item => {
    const rulesMatch = item.display_description.match(/__rules(.*)/);
    if (!rulesMatch) return true;

    const ruleset = rulesMatch[0];
    const monthValid = isMonthValid(ruleset, selectedDate);
    const dayValid = isDayValid(ruleset, selectedDate);

    const itemDecision = monthValid && dayValid;
    
    return itemDecision;
  }).map(item => ({
    ...item,
    display_description: item.display_description.split('__rules')[0]
  }));

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{language === 'NL' ? 'Uw Informatie' : language === 'FR' ? 'Vos Informations' : 'Your Information'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlertVerified && (
          <Alert className="validation-alert" variant="warning" onClose={() => setShowAlert(false)} dismissible>
            {language === 'NL' ? 'Controleer uw e-mail' : language === 'FR' ? 'Vérifiez votre email' : 'Please check your email'}
          </Alert>
        )}
        {showAlert && errors.firstName && (
  <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
    {language === 'NL'
      ? 'Controleer uw voornaam'
      : language === 'FR'
      ? 'Vérifiez votre prénom'
      : 'Please check your first name'}
  </Alert>
)}

{showAlert && errors.lastName && (
  <Alert className="validation-alert" variant="warning" onClose={() => setShowAlert(false)} dismissible>
    {language === 'NL'
      ? 'Controleer uw achternaam'
      : language === 'FR'
      ? 'Vérifiez votre nom'
      : 'Please check your last name'}
  </Alert>
)}

{showAlert && errors.email && (
  <Alert className="validation-alert" variant="warning" onClose={() => setShowAlert(false)} dismissible>
    {language === 'NL'
      ? 'Controleer uw e-mail'
      : language === 'FR'
      ? 'Vérifiez votre email'
      : 'Please check your email'}
  </Alert>
)}

{showAlert && errors.phone && (
  <Alert className="validation-alert" variant="warning" onClose={() => setShowAlert(false)} dismissible>
    {language === 'NL'
      ? 'Controleer uw telefoonnummer'
      : language === 'FR'
      ? 'Vérifiez votre numéro de téléphone'
      : 'Please check your phone number'}
  </Alert>
)}

{showAlert && !showAlertVerified && !errors.phone && !errors.email && !errors.firstName && !errors.lastName && (
  <Alert className="validation-alert" variant="warning" onClose={() => setShowAlert(false)} dismissible>
    {language === 'NL'
      ? 'U moet akkoord gaan met de voorwaarden'
      : language === 'FR'
      ? 'Vous devez accepter les termes'
      : 'You must agree to the terms'}
  </Alert>
)}

        {showAlertPrivacy.visible && (
          <Alert variant="warning" onClose={() => setShowAlertPrivacy({ visible: false, message: '' })} dismissible>
            {showAlertPrivacy.message}
          </Alert>
        )}

{/* 
<GoogleOAuthProvider clientId="mateza-oauth" className='mb-10'>
      
      <div className="google-signin">
        <GoogleLogin
          useOneTap
          text="continue_with"
          shape="pill"
          size="large"
          logo_alignment="center"
        />
      </div>
</GoogleOAuthProvider>

<p className='or-text'>OF</p> 
*/}



        <div className='info-block-new'>
        <p className="lightblue-text">
  <FontAwesomeIcon style={{marginRight:"5px"}} icon={faCalendarAlt} /> {/* Calendar icon */}
  {language === 'NL' ? 'Datum: ' : language === 'FR' ? 'Date: ' : 'Date: '}
  {selectedDate ? format(selectedDate, 'd MMMM yyyy', { locale: language === 'NL' ? nl : fr }) : ''}
</p>

<p className="lightblue-text">
  <FontAwesomeIcon icon={faClock} style={{marginRight:"5px"}} /> {/* Clock icon */}
  {language === 'NL' ? 'Tijd: ' : language === 'FR' ? 'Heure: ' : 'Time: '}
  {(() => {
    let shiftDescription = window.shift ? window.shift.find(shift => shift.number === Number(selectedSlot))?.description : null;
    if (window.waitingListSelection) {
      shiftDescription = window.shiftDescriptionWaitingList;
    }
    return shiftDescription || (selectedSlot !== null ? (isQuarterSlot ? slotToQuarterTime(Number(selectedSlot)) : slotToTime(Number(selectedSlot))) : '');
  })()}
</p>

<p onClick={handleGuestsClick} style={{ cursor: 'pointer' }} className="lightblue-text">
  <FontAwesomeIcon icon={faUsers} style={{marginRight:"5px"}}/> {/* Guests icon */}
  {language === 'NL' ? 'Aantal Personen: ' : language === 'FR' ? 'Nombre de Convives: ' : 'Number of Guests: '}
  {guests}
</p>
</div>
        {isSubmitting && (
          <ProgressBar now={progress} animated variant="success" className="mb-3"/>
        )}

        <Form>
          {filteredMenuItems.length > 0 && (
            <Form.Group controlId="formMenu" className="mt-2 mb-2">
              <Form.Label>{language === 'NL' ? 'Menu' : language === 'FR' ? 'Menu' : 'Menu'}</Form.Label>
              <Form.Control
                as="select"
                value={selectedMenu}
                onChange={(e) => setSelectedMenu(e.target.value)}
                isInvalid={!!errors.selectedMenu}
              >
                <option value="">{language === 'NL' ? 'Selecteer een menu' : language === 'FR' ? 'Sélectionnez un menu' : 'Select a menu'}</option>
                {filteredMenuItems.map((menu) => (
                  <option key={menu.id} value={menu.display_description}>{menu.display_description}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid" className="feedback-orange">
                {errors.selectedMenu}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {isEditing ? (
            <>


<Form.Group controlId="formFirstName">
  <div className="input-container">
    <div className="input-icon">
      <FontAwesomeIcon icon={faUser} />
    </div>
    <div className="input-wrapper">
      <Form.Control
        type="text"
        id="formFirstName"
        placeholder={
          language === 'NL'
            ? 'Voornaam'
            : language === 'FR'
            ? 'Prénom'
            : 'First Name'
        }
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onBlur={() => {
          if (firstName.trim() !== "") {
            setFirstNameValid(true);
            setErrors((prevErrors) => ({ ...prevErrors, firstName: null }));
          } else {
            setFirstNameValid(false);
            setErrors((prevErrors) => ({
              ...prevErrors,
              firstName: language === 'NL' ? 'Voornaam is verplicht' : language === 'FR' ? 'Le prénom est requis' : 'First name is required',
            }));
          }
        }}
        onKeyDown={(event) => handleKeyPress(event, 'formLastName', null)} // No previous field for firstName
        onFocus={() => handleFocus('formFirstName')}
        isInvalid={!!errors.firstName}
        className="input-field"
      />
    </div>
  </div>
</Form.Group>

<Form.Group controlId="formLastName">
  <div className="input-container">
    <div className="input-icon">
      <FontAwesomeIcon icon={faIdCard} />
    </div>
    <div className="input-wrapper">
      <Form.Control
        type="text"
        id="formLastName"
        placeholder={
          language === 'NL'
            ? 'Achternaam'
            : language === 'FR'
            ? 'Nom de famille'
            : 'Last Name'
        }
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onBlur={() => {
          if (lastName.trim() !== "") {
            setLastNameValid(true);
            setErrors((prevErrors) => ({ ...prevErrors, lastName: null }));
          } else {
            setLastNameValid(false);
            setErrors((prevErrors) => ({
              ...prevErrors,
              lastName: language === 'NL' ? 'Achternaam is verplicht' : language === 'FR' ? 'Le nom est requis' : 'Last name is required',
            }));
          }
        }}
        onKeyDown={(event) => handleKeyPress(event, 'formEmail', 'formFirstName')} // Navigate between firstName and email
        onFocus={() => handleFocus('formLastName')}
        isInvalid={!!errors.lastName}
        className="input-field"
      />
    </div>
  </div>
</Form.Group>

<Form.Group controlId="formEmail">
  <div className="input-container">
    <div className="input-icon">
      <FontAwesomeIcon icon={faEnvelope} />
    </div>
    <div className="input-wrapper">
      <Form.Control
        type="email"
        id="formEmail"
        placeholder={
          language === 'NL'
            ? 'E-mail'
            : language === 'FR'
            ? 'Adresse e-mail'
            : 'Email'
        }
        value={email}
        onChange={(e) => handleEmailChange(e)}
        onBlur={() => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (emailRegex.test(email)) {
            setEmailValid(true);
            setErrors((prevErrors) => ({ ...prevErrors, email: null }));
          } else {
            setEmailValid(false);
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: language === 'NL' ? 'Ongeldig emailadres' : language === 'FR' ? 'Adresse e-mail invalide' : 'Invalid email address',
            }));
          }
        }}
        onKeyDown={(event) => handleKeyPress(event, 'formPhone', 'formLastName')} // Navigate between lastName and phone
        onFocus={() => handleFocus('formEmail')}
        isInvalid={!!errors.email}
        className="input-field"
      />
    </div>
  </div>
</Form.Group>

<Form.Group controlId="formPhone">
  <div className="input-container">
    <div className="input-icon">
      <FontAwesomeIcon icon={faPhone} />
    </div>
    <div className="input-wrapper">
      <Form.Control
        type="tel"
        id="formPhone"
        placeholder={
          language === 'NL'
            ? 'Telefoon'
            : language === 'FR'
            ? 'Téléphone'
            : 'Phone'
        }
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        onBlur={() => {
          if (phone.trim() !== "") {
            setPhoneValid(true);
            setErrors((prevErrors) => ({ ...prevErrors, phone: null }));
          } else {
            setPhoneValid(false);
            setErrors((prevErrors) => ({
              ...prevErrors,
              phone: language === 'NL' ? 'Telefoonnummer is verplicht' : language === 'FR' ? 'Le numéro de téléphone est requis' : 'Phone number is required',
            }));
          }
        }}
        onKeyDown={(event) => handleKeyPress(event, 'formSubmit', 'formEmail')} // Navigate between email and submit
        onFocus={() => handleFocus('formPhone')}
        isInvalid={!!errors.phone}
        className="input-field"
      />
    </div>
  </div>
        </Form.Group>
            

        <Form.Group controlId="formExtraInfo" className="mt-2">
          <Form.Label>
            {language === 'NL'
              ? 'Extra Informatie / Allergenen'
              : language === 'FR'
              ? 'Informations Supplémentaires / Allergènes'
              : 'Extra Information / Allergens'}
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={
              language === 'NL'
                ? 'Vul extra informatie in'
                : language === 'FR'
                ? 'Entrez des informations supplémentaires'
                : 'Enter extra information'
            }
            value={extraInfo}
            onChange={(e) => setExtraInfo(e.target.value)}
          />
        </Form.Group>
      


            


              

            </>
          ) : (
            <div className="info-block">
              <div className="info-item">
                <span>{language === 'NL' ? 'Voornaam' : language === 'FR' ? 'Prénom' : 'First Name'}: {firstName}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Achternaam' : language === 'FR' ? 'Nom de Famille' : 'Last Name'}: {lastName}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Email' : language === 'FR' ? 'Email' : 'Email'}: {email}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Telefoon' : language === 'FR' ? 'Téléphone' : 'Phone'}: {phone}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Extra Informatie' : language === 'FR' ? 'Informations Supplémentaires' : 'Extra Information'}: {extraInfo}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
            </div>
          )}
<Form.Group controlId="formAgreedTerms" className="mt-2">
  <Form.Check
    type="checkbox"
    label={
      <span>
        {language === 'NL' ? 'Ik ga akkoord met de ' : language === 'FR' ? 'J\'accepte les ' : 'I agree with the '}
        <a href="https://voorwaarden.reservaties.net" target="_blank">
          {language === 'NL' ? 'privacy policy' : language === 'FR' ? 'politique de protection des données' : 'consumer privacy policy'}
        </a>
      </span>
    }
    checked={agreedTerms}
    onChange={(e) => {
      const isChecked = e.target.checked;

      // Update agreedTerms state
      setAgreedTerms(isChecked);

      // Validate and update errors state
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        if (!isChecked) {
          // Show privacy alert if unchecked
          setShowAlert(true);
          newErrors.agreedTerms = language === 'NL' ? 'U moet akkoord gaan met de voorwaarden' : language === 'FR' ? 'Vous devez accepter les termes' : 'You must agree to the terms';
        } else {
          // Remove the error and alert when checked
          setShowAlert(false);
          delete newErrors.agreedTerms;
        }

        return newErrors;
      });
    }}
    isInvalid={!!errors.agreedTerms}
  />

  <Form.Control.Feedback type="invalid" className="feedback-orange">
    {errors.agreedTerms}
  </Form.Control.Feedback>
</Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isSubmitting ? (
          <Spinner animation="border" />
        ) : (
          <>
            {isEditing || !isAllDataFilled() ? (
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                {language === 'NL' ? 'Sluiten' : language === 'FR' ? 'Fermer' : 'Close'}
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setIsEditing(true)}>
                {language === 'NL' ? 'Bewerken' : language === 'FR' ? 'Modifier' : 'Edit'}
              </Button>
            )}
            <Button 
            variant="primary" 
            onClick={handleFormSubmit} 
          >
            {language === 'NL' ? 'Bevestigen' : language === 'FR' ? 'Confirmer' : 'Confirm'}
          </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationModal;
