import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { formatDateToGMT2, isSameDay, slotToDate } from '../utils/timeslot';

const Timeslots = ({ selectedDate, slotData, guests, setSelectedSlot, language, clearSelectedSlot }) => {
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [availableIntervals, setAvailableIntervals] = useState([]);
  const [unavailableIntervals, setUnavailableIntervals] = useState([]);
  const [localSelectedSlot, setLocalSelectedSlot] = useState(null);
  const [shiftData, setShiftData] = useState([]);

  useEffect(() => {
    setSelectedInterval(null);
    if (selectedDate) {
      updateAvailableIntervals();
      updateUnavailableIntervals();
    }
  }, [selectedDate]);

  const shiftLength = window.shiftLength || 4;

  const handleSlotSelect = (slot, isQuarter, interval) => {
	window.waitingListSelection = true;
    setLocalSelectedSlot(slot);
    setSelectedSlot(slot, isQuarter);
    setSelectedInterval(interval);

    const shiftDescription = {
      NL: interval === 'Night' ? 'Wachtlijst - Nacht' : 
          interval === 'Morning' ? 'Wachtlijst - Ochtend' : 
          interval === 'Afternoon' ? 'Wachtlijst - Middag' : 
          'Wachtlijst - Avond',
      FR: interval === 'Night' ? 'Liste D\'attente - Nuit' : 
          interval === 'Morning' ? 'Liste D\'attente - Matin' : 
          interval === 'Afternoon' ? 'Liste D\'attente - Après-Midi' : 
          'Liste D\'attente - Soir',
      EN: interval === 'Night' ? 'Waitlist - Night' : 
          interval === 'Morning' ? 'Waitlist - Morning' : 
          interval === 'Afternoon' ? 'Waitlist - Afternoon' : 
          'Waitlist - Evening',
    };

    window.shiftDescriptionWaitingList = shiftDescription[language];
    localStorage.setItem('shiftDescription', shiftDescription[language]);
  };

  const updateUnavailableIntervals = () => {
    const formattedDate = formatDateToGMT2(selectedDate);
    const timeslots = slotData[formattedDate] || {};
    const unavailableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        const isSlotValid = timeslots[slot] >= guests;
        if (isToday) {
          const slotTime = slotToDate(slot, selectedDate);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);
          return isSlotValid && slotTime >= currentTimePlus4Hours;
        }
        return isSlotValid;
      })
      .map(Number);

    const checkConsecutiveSlots = (startSlot) => {
      for (let i = 0; i < shiftLength; i++) {
        if (!unavailableTimeslots.includes(startSlot + i)) {
          return false;
        }
      }
      return true;
    };

    const intervals = {
      Night: unavailableTimeslots.some(slot => slot >= 1 && slot < 14 && checkConsecutiveSlots(slot)),
      Morning: unavailableTimeslots.some(slot => slot >= 14 && slot < 25 && checkConsecutiveSlots(slot)),
      Afternoon: unavailableTimeslots.some(slot => slot >= 25 && slot < 34 && checkConsecutiveSlots(slot)),
      Evening: unavailableTimeslots.some(slot => slot >= 34 && slot <= 47 && checkConsecutiveSlots(slot)),
    };

    const filteredUnavailableIntervals = Object.keys(intervals).filter(interval => intervals[interval]);
    setUnavailableIntervals(filteredUnavailableIntervals);
  };

  const updateAvailableIntervals = () => {
    const formattedDate = formatDateToGMT2(selectedDate);
    const timeslots = slotData[formattedDate] || {};
    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        const isSlotValid = timeslots[slot] < guests;
        if (isToday) {
          const slotTime = slotToDate(slot, selectedDate);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);
          return isSlotValid && slotTime >= currentTimePlus4Hours;
        }
        return isSlotValid;
      })
      .map(Number);

    const checkConsecutiveSlots = (startSlot) => {
      for (let i = 0; i < shiftLength; i++) {
        if (!availableTimeslots.includes(startSlot + i)) {
          return false;
        }
      }
      return true;
    };

    const intervals = {
      Night: availableTimeslots.some(slot => slot >= 1 && slot < 14 && checkConsecutiveSlots(slot)),
      Morning: availableTimeslots.some(slot => slot >= 14 && slot < 24 && checkConsecutiveSlots(slot)),
      Afternoon: availableTimeslots.some(slot => slot >= 24 && slot < 34 && checkConsecutiveSlots(slot)),
      Evening: availableTimeslots.some(slot => slot >= 34 && slot <= 47 && checkConsecutiveSlots(slot)),
    };

    const filteredAvailableIntervals = Object.keys(intervals).filter(interval => intervals[interval]);
    setAvailableIntervals(filteredAvailableIntervals);
  };

  const getFilteredIntervals = () => {
    // Filter out intervals that appear in both available and unavailable lists
    return availableIntervals.filter(interval => !unavailableIntervals.includes(interval));
  };

  if (!selectedInterval) {
    const filteredIntervals = getFilteredIntervals();

    return (
      <div className="timeslot-buttons">
        {filteredIntervals.map((interval, index) => (
          <Button
            key={index}
            className="m-1 timeslot-button"
            onClick={() => handleSlotSelect(5, false, interval)}
          >
            {language === 'NL' 
              ? interval === 'Night' 
                ? 'Wachtlijst - Nacht' 
                : interval === 'Morning' 
                ? 'Wachtlijst - Ochtend' 
                : interval === 'Afternoon' 
                  ? 'Wachtlijst - Middag' 
                  : 'Wachtlijst - Avond'
              : language === 'FR' 
                ? interval === 'Night' 
                  ? 'Liste D\'attente - Nuit' 
                  : interval === 'Morning' 
                    ? 'Liste D\'attente - Matin' 
                    : interval === 'Afternoon' 
                      ? 'Liste D\'attente - Après-Midi' 
                      : 'Liste D\'attente - Soir'
              : interval === 'Night' 
                ? 'Waitlist - Night' 
                : interval === 'Morning' 
                ? 'Waitlist - Morning' 
                : interval === 'Afternoon' 
                  ? 'Waitlist - Afternoon' 
                  : 'Waitlist - Evening'}
          </Button>
        ))}
      </div>
    );
  }

  return null;
};

export default Timeslots;
