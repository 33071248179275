import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import LoadingMessages from '../components/LoadingMessages';
import "./css/Loading.css";
import animationData from '../assets/loading.json';
import Lottie from 'lottie-react';

const LoadingPage = ({ language }) => {
  const [progress, setProgress] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setProgress(25), 1000),
      setTimeout(() => setProgress(50), 2000),
      setTimeout(() => setProgress(75), 3000),
      setTimeout(() => setProgress(100), 4000),
    ];

    return () => timeouts.forEach(clearTimeout); // Clean up timeouts on unmount
  }, []);

  useEffect(() => {
    const loadAnimation = () => {
      // Assuming the animation is preloaded by react-lottie
      setLoaded(true);
    };

    loadAnimation();
  }, []);
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Lottie animationData={animationData} loop={true} style={{ height: 300, width: 300 }} />
      <LoadingMessages language={language} />
      <ProgressBar now={progress} label={`${progress}%`} style={{ marginTop: '10px', width: '200px' }} />
    </div>
  );
};

export default LoadingPage;
