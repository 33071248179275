import React, { useEffect, useState } from 'react';
import "./css/Loading.css";
import animationData from '../assets/init.json';
import Lottie from 'lottie-react';
import { Spinner } from 'react-bootstrap'; // Import the Bootstrap Spinner


const LoadingPage = ({ language }) => {
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [opacity, setOpacity] = useState(1); // Control opacity
  const [scale, setScale] = useState(1); // Control zoom effect
  const [visible, setVisible] = useState(true); // Control visibility (display none)

  // Handle progress increments
  useEffect(() => {
    const timeouts = [
      setTimeout(() => setProgress(25), 1000),
      setTimeout(() => setProgress(50), 2000),
      setTimeout(() => setProgress(75), 3000),
      setTimeout(() => setProgress(100), 4000),
    ];

    return () => timeouts.forEach(clearTimeout);
  }, []);

  // Simulate loading complete
  useEffect(() => {
    const loadAnimation = () => {
      setLoaded(true);
    };

    loadAnimation();
  }, []);

  // Handle the opacity and scale transition after 3 seconds and hide after 5 seconds
  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setOpacity(0); // Change opacity to 0 after 3 seconds
      setScale(1.5); // Zoom the octopus by increasing scale
    }, 3000);

    const hideTimer = setTimeout(() => {
      setVisible(false); // Set display to none after 5 seconds
    }, 4000);

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  if (!visible) {
    return null; // Hide the component by returning null if not visible
  }

  return (
    <div
      style={{
        display: visible ? 'flex' : 'none', // Control display with visibility state
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: "#eeeee4",
        position: "fixed",
        zIndex: "1000000",
        opacity: opacity, // Bind opacity to the state
        transition: 'opacity 1s ease, transform 1s ease', // Smooth transition for both opacity and scale
      }}
    >
      <div
        style={{
          transition: 'opacity 1s ease, transform 1s ease', // Smooth transition for both opacity and scale
          transform: `scale(${scale})`, // Zoom in the octopus
        }}
      >
      <Lottie
        animationData={animationData}
        loop={true}
        style={{
          height: window.innerWidth <= 768 ? '250px' : '320px',
          width: window.innerWidth <= 768 ? '250px' : '320px',
          transition: 'opacity 1s ease, transform 1s ease',
        }}
      />
      </div>
      <div style={{ marginTop: '-20px' }}>
      <span
      style={{
        fontSize: window.innerWidth <= 768 ? '14px' : '16.5px', // Smaller font size on mobile
      }}
    >
      Reserveer met een glimlach bij {window.restaurantName && window.restaurantName.trim() !== "" ? window.restaurantName : "dit restaurant"}!
    </span>

    </div>

      <Spinner animation="border" role="status" style={{ marginTop: '20px' }}>
      </Spinner>
      

      <div>

      <div style={{ position: 'fixed', bottom: '20px', width: '100vw', left: '0', right:'0', textAlign:'center' }}>
      
      <div>
      <hr style={{ border: '1px solid lightgray' }} />
      <div style={{ textAlign: 'center' }}>
      <p style={{ color: 'gray', fontSize: 'small' }}>
      <a href="https://reservatiesysteem.com" style={{ color: 'gray', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
        Mateza. Belgische IT voor Belgische restaurants. ❤️
      </a>
    </p>

    <p style={{ color: 'gray', fontSize: 'small', marginTop:'-10px' }}>
      <a href="https://mateza.be" style={{ color: 'gray', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
        Info op
      </a>
      {' '} 
      <a href="https://mateza.be" style={{ color: 'gray' }} target="_blank" rel="noopener noreferrer">
      mateza.be
      </a>
    </p>

      </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default LoadingPage;
